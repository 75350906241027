import beforeRenderRedirect from 'web-client/screens/Login/beforeRenderRedirect'
import lazyRetry from 'shared/utils/lazyRetry'
import RedirectToHome from 'web-client/components/RedirectToHome'

export const brownRoutes = [
  { name: 'partner/dispatch/new', path: '/partner/dispatch/new' },
  { name: 'partner/dispatch/edit', path: '/partner/dispatch/edit/:jobId' },
  { name: 'partner/dispatch/assign-partner', path: '/partner/dispatch/assign-partner/:jobId' },
  { name: 'partner/dispatch', path: '/partner/dispatch' },
  { name: 'dispatch', path: '/dispatch' },

  { name: 'partner/fleet', path: '/partner/fleet' },
  { name: 'fleet/map', path: '/fleet/map' },
  { name: 'client/map', path: '/client/map' },
  { name: 'partner/invoices', path: '/partner/invoices' },
  { name: 'fleet/invoices', path: '/fleet/invoices' },
  { name: 'client/invoices', path: '/client/invoices' },
  { name: 'invoices', path: '/invoices' },
  { name: 'partner/reporting', path: '/partner/reporting' },
  { name: 'fleet/reporting', path: '/fleet/reporting' },
  { name: 'client/reporting', path: '/client/reporting' },
  { name: 'reporting', path: '/reporting' },
  { name: 'partner/insights', path: '/partner/insights' },
  { name: 'fleet/insights', path: '/fleet/insights' },
  { name: 'client/insights', path: '/client/insights' },
  { name: 'insights', path: '/insights' },
  { name: 'fleet/dashboard', path: '/fleet/dashboard' },
  { name: 'client/dashboard', path: '/client/dashboard' },
  { name: 'fleet', path: '/fleet' },
  { name: 'client', path: '/client' },
  { name: 'user/settings', path: '/user/settings' },
  { name: 'test', path: '/test' },
  { name: 'fleet/reviews', path: '/fleet/reviews' },
  { name: 'client/reviews', path: '/client/reviews' },
  { name: 'partner/reviews', path: '/partner/reviews' },
  { name: 'reviews', path: '/reviews' },
  { name: 'jobs/show/vin', path: '/jobs/show/vin/:vin' },
  { name: 'auth/partner', path: '/auth/partner', requireAuthentication: false },
  { name: 'oauth/authorize', path: '/oauth/authorize', requireAuthentication: false },
  {
    name: 'auth/email_verifications',
    path: '/auth/email_verifications/:uuid',
    requireAuthentication: false,
  },
  {
    name: 'auth/fallback_requests',
    path: '/auth/fallback_requests/:uuid',
    requireAuthentication: false,
  },
  {
    name: 'auth/password_requests',
    path: '/auth/password_requests/:uuid',
    requireAuthentication: false,
  },
  { name: 'partner/setupBilling', path: '/partner/setupBilling' },
  { name: 'postal_codes', path: '/postal_codes' },

  {
    name: 'dashboard/new',
    path: '/dashboard/new',
  },
  {
    name: 'dashboard/edit',
    path: '/dashboard/edit/:jobId',
  },
  {
    name: 'dashboard',
    path: '/dashboard',
  },
  {
    name: 'settings',
    path: '/settings',
  },
  {
    name: 'settingsScreen',
    path: '/settings/:name',
  },
  {
    name: 'rateAgreement',
    path: '/settings/rate-agreements/:id',
  },
  {
    name: 'rateAgreementRates',
    path: '/settings/rate-agreements/:id/rates',
  },
  {
    name: 'rateAgreementCapabilities',
    path: '/settings/rate-agreements/:id/capabilities',
  },
  {
    name: 'rateAgreementClientPreferences',
    path: '/settings/rate-agreements/:id/client-preferences',
  },
  {
    name: 'companyDetails',
    path: '/settings/companies/:id',
  },
  {
    name: 'companyDetailsDispatch',
    path: '/settings/companies/:id/dispatch',
  },
  {
    name: 'companyDetailsRateAgreements',
    path: '/settings/companies/:id/rate-agreements',
  },
  {
    name: 'companyDetailsUserBackgroundChecks',
    path: '/settings/companies/:id/users',
  },
  {
    name: 'companyDetailsProgramsCoverage',
    path: '/settings/companies/:id/programs-coverage',
  },
  {
    name: 'companyDetailsTowDestinations',
    path: '/settings/companies/:id/tow-destinations',
  },
  {
    name: 'map',
    path: '/map',
  },

  {
    name: 'caseManagement',
    path: '/case/:caseId',
  },
  {
    name: 'tasks',
    path: '/tasks',
  },
  {
    name: 'client/tasks',
    path: '/client/tasks',
  },
  {
    name: 'partner/tasks',
    path: '/partner/tasks',
  },
  {
    name: 'resources',
    path: '/resources',
  },
  {
    name: 'partner/resources',
    path: '/partner/resources',
  },
  {
    name: 'client/resources',
    path: '/client/resources',
  },
]

export const greenRoutes = [
  {
    name: 'home',
    path: '/',
    component: RedirectToHome,
    hideFooter: true,
  },
  // { // Temporarily(?) moving this to a brownfield route, for the sake of dependencies
  //   name: 'caseManagement',
  //   path: '/case/:caseId',
  //   requireAuthentication: true,
  //   component: lazyRetry(() => import('web-client/screens/CaseManagement'))),
  //   hideFooter: false,
  //   hideNavigation: false,
  // },
  {
    name: 'forgotPassword',
    path: '/forgotPassword?lockedOut?email',
    component: lazyRetry(() => import('web-client/screens/ForgotPassword')),
    hideFooter: true,
  },
  {
    name: 'user/reset',
    path: '/user/reset',
    component: lazyRetry(() => import('web-client/screens/ResetPassword')),
    hideFooter: true,
  },
  {
    name: 'login',
    path: '/login',
    component: lazyRetry(() => import('web-client/screens/Login/LoginRouter')),
    hideFooter: true,
  },
  {
    name: 'redirectToIdentityProvider',
    path: '/login/:code',
    beforeRender: beforeRenderRedirect,
    component: lazyRetry(() => import('web-client/screens/Login/RedirectToIdentityProvider')),
    hideFooter: true,
  },
]

const routes = [
  ...brownRoutes.map((route) => ({ requireAuthentication: true, ...route })),
  ...greenRoutes.map((route) => ({ ...route, green: true })),
]

export default routes
